<template>
  <div>
    <list-template
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :loading="loading"
      :current-page="page"
      @onAdd="handleAdd"
      @onHandle="handleClick"
      @onSearch="onSearch"
      @onReset="onReset"
      @onChangePage="handleCurrentChange"
      @onChange="onChange"
    >
    </list-template>
    <Dialog :visible.sync="visible" :title="title" showLine submitText="保存" :closeOnClickModal="false" :beforeSubmit="onBeforeClose" @close="onClose">
      <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="80rem">
        <el-row gutter="20">
          <el-col span="12">
            <el-form-item label="最低分" prop="min_score">
              <el-input v-model="form.min_score" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col span="12">
            <el-form-item label="最高分" prop="max_score">
              <el-input v-model="form.max_score" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tag-box">
          <el-row gutter="20" class="tag-inner">
            <el-col span="4">
              <el-form-item label="" labelWidth="0" prop="t1">
                <el-input v-model="form.t1" placeholder="标签1" maxlength="6"></el-input>
              </el-form-item>
            </el-col>
            <el-col span="4">
              <el-form-item label="" labelWidth="0" prop="t2">
                <el-input v-model="form.t2" placeholder="标签2" maxlength="6"></el-input>
              </el-form-item>
            </el-col>
            <el-col span="4">
              <el-form-item label="" labelWidth="0" prop="t3">
                <el-input v-model="form.t3" placeholder="标签3" maxlength="6"></el-input>
              </el-form-item>
            </el-col>
            <el-col span="4">
              <el-form-item label="" labelWidth="0" prop="t4">
                <el-input v-model="form.t4" placeholder="标签4" maxlength="6"></el-input>
              </el-form-item>
            </el-col>
            <el-col span="4">
              <el-form-item label="" labelWidth="0" prop="t5">
                <el-input v-model="form.t5" placeholder="标签5" maxlength="6"></el-input>
              </el-form-item>
            </el-col>
            <el-col span="4">
              <el-form-item label="" labelWidth="0" prop="t6">
                <el-input v-model="form.t6" placeholder="标签6" maxlength="6"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-form-item label="" labelWidth="0" prop="content">
          <el-input v-model="form.content" type="textarea" placeholder="请输入文案内容" maxlength="300" show-word-limit :autosize="{ minRows: 6, maxRows: 8 }"></el-input>
        </el-form-item>
      </el-form>
    </Dialog>
  </div>
</template>

<script>
import { tableListMixin } from '@/utils/mixins'
import { validateNumber } from '@/utils/validate'
import Dialog from '@/components/common/Dialog'
import http from '@/api'
export default {
  _config:{'route':{'path':'list','meta':{'title':'列表','keepAlive':true}}},
  mixins: [tableListMixin],
  components: { Dialog },
  data() {
    const validatorFunc = (value, callback) => validateNumber(value, callback, { min: 0, max: 10000, equalToMin: true, equalToMax: true })
    const validatorMin = (_, value, callback) => {
      if (this.isChecked1) {
        if (this.form.max_score === '') {
          if (this.isChecked2) {
            this.isChecked1 = false
            this.isChecked2 = false
          } else {
            this.$refs.form.validateField('max_score')
          }
        }
        if (value === '') return callback(new Error('请输入最低分'))
        if (Number(this.form.min_score) >= Number(this.form.max_score)) {
          callback(new Error('最低分需小于最高分'))
        } else {
          validatorFunc(value, callback)
        }
        if (this.isChecked2) {
          this.isChecked1 = false
          this.isChecked2 = false
          return
        }
        this.$refs.form.validateField('max_score')
        return
      }
      if (this.form.min_score === '') {
        callback(new Error('请输入最低分'))
        if (this.isChecked2 && this.form.max_score === '') {
          this.isChecked2 = false
          this.$refs.form.validateField('max_score')
        }
        return
      }
      if (this.form.max_score === '') {
        this.$refs.form.validateField('max_score')
        validatorFunc(value, callback)
      } else {
        if (Number(this.form.min_score) >= Number(this.form.max_score)) {
          return callback(new Error('最低分需小于最高分'))
        }
        validatorFunc(value, callback)
        if (!this.isChecked2) {
          this.isChecked2 = true
          this.$refs.form.validateField('max_score')
        }
      }
    }
    const validatorMax = (_, value, callback) => {
      if (this.isChecked2) {
        if (this.form.min_score === '') {
          if (this.isChecked1) {
            this.isChecked1 = false
            this.isChecked2 = false
          } else {
            this.$refs.form.validateField('min_score')
          }
        }
        if (value === '') return callback(new Error('请输入最高分'))
        if (Number(this.form.min_score) >= Number(this.form.max_score)) {
          callback(new Error('最高分需大于最低分'))
        } else {
          validatorFunc(value, callback)
        }
        if (this.isChecked1) {
          this.isChecked1 = false
          this.isChecked2 = false
          return
        }
        this.$refs.form.validateField('min_score')
        return
      }
      if (this.form.max_score === '') {
        callback(new Error('请输入最高分'))
        if (this.isChecked1 && this.form.min_score === '') {
          this.isChecked1 = false
          this.$refs.form.validateField('min_score')
        }
        return
      }
      if (this.form.min_score === '') {
        this.$refs.form.validateField('min_score')
        validatorFunc(value, callback)
      } else {
        if (Number(this.form.min_score) >= Number(this.form.max_score)) {
          return callback(new Error('最高分需大于最低分'))
        }
        validatorFunc(value, callback)
        if (!this.isChecked1) {
          this.isChecked1 = true
          this.$refs.form.validateField('min_score')
        }
      }
    }
    return {
      visible: false,
      title: '',
      id: '',
      isChecked1: false,
      isChecked2: false,
      form: {
        min_score: '',
        max_score: '',
        t1: '',
        t2: '',
        t3: '',
        t4: '',
        t5: '',
        t6: '',
        content: ''
      },
      rules: {
        min_score: [{ required: true, validator: validatorMin, trigger: 'change' }],
        max_score: [{ required: true, validator: validatorMax, trigger: 'change' }],
        t1: [{ required: true, message: '请输入', trigger: 'change' }],
        t2: [{ required: true, message: '请输入', trigger: 'change' }],
        t3: [{ required: true, message: '请输入', trigger: 'change' }],
        t4: [{ required: true, message: '请输入', trigger: 'change' }],
        t5: [{ required: true, message: '请输入', trigger: 'change' }],
        t6: [{ required: true, message: '请输入', trigger: 'change' }],
        content: [{ required: true, message: '请输入', trigger: 'change' }],
      },
      tableConfig: [
        { prop: 'content', label: '文案内容' },
        { prop: 'min_score', label: '最低分' },
        { prop: 'max_score', label: '最高分' },
        { prop: 'creator', label: '创建人' },
        { prop: 'created_at', label: '创建时间' },
        { label: '操作', width: '100rem', handle: true }
      ]
    }
  },
  methods: {
    getData() {
      this.getDataApi('/ascend-gravity/douyin-config/list')
    },
    handleAdd() {
      this.visible = true
      this.title = '新增文案'
    },
    handleClick(row) {
      this.visible = true
      this.title = '编辑文案'
      const { min_score, max_score, content, label, id } = row
      this.id = id
      this.form = {
        min_score,
        max_score,
        content,
        t1: label[0],
        t2: label[1],
        t3: label[2],
        t4: label[3],
        t5: label[4],
        t6: label[5]
      }
    },
    onClose() {
      this.form = { min_score: '', max_score: '', content: '', t1: '', t2: '', t3: '', t4: '', t5: '', t6: '' }
      this.isChecked1 = false
      this.isChecked2 = false
      this.id = ''
    },
    onBeforeClose(done, instance) {
      this.$refs.form.validate((valid) => {
        if(valid) {
          const url = `/ascend-gravity/douyin-config/${this.id ? 'update' : 'create'}`
          const { t1, t2, t3, t4, t5, t6, ...other} = this.form
          const data = { ...other, label: [t1, t2, t3, t4, t5, t6] }
          if (this.id) data.id = this.id
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = '保存中...'
          http({ url, method: 'post'}, data).then(() => {
            this.$message.success(`${this.title}成功`)
            this.getData()
            done()
          }).catch(() => {
            instance.confirmButtonLoading = false
            instance.confirmButtonText = '确定'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-box {
  margin-bottom: 20rem;
  overflow: hidden;
}
.tag-inner {
  margin-bottom: -22rem;
  padding: 20rem 20rem 0;
  background-color: #F7F7F7;
}
</style>
